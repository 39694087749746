// Entry point for the build script in your package.json
import "./controllers"
import * as bootstrap from "bootstrap"

document.addEventListener('DOMContentLoaded', (event) => {
  setTimeout(() => {
    let flashMessage = document.getElementById('flash-message-container');
    let fadeEffect = setInterval(() => {
        if (!flashMessage.style.opacity) {
            flashMessage.style.opacity = 1;
        }
        if (flashMessage.style.opacity > 0) {
            flashMessage.style.opacity -= 0.1;
        } else {
            clearInterval(fadeEffect);
            flashMessage.style.display = 'none';
        }
    }, 50);
  }, 3000);
});